"use strict";

exports.__esModule = true;
exports.createRoute = createRoute;

var _urlify = require("../../html/urlify"); // T: return type of getData and the Props type of the React Component for the Route


function createRoute(rootPath, subPath, noindex) {
  if (noindex === void 0) {
    noindex = false;
  }

  var path = (0, _urlify.urlify)(subPath);
  var component = rootPath + subPath;
  return {
    path: path,
    component: component,
    noindex: noindex
  };
}